import { useEffect, useState } from "react"
import Fuse from "fuse.js"
import Link from "next/link"
import { useRouter } from "next/router"
import Meta from "components/Meta"
import { getGlobals } from "storyblok/api"
import CallToAction from "components/CallToAction"

export default function Page({ meta }) {
  const router = useRouter()
  const [results, setResults] = useState()

  useEffect(() => {
    const URL = /https?:\/\/.*(?=<\/loc>)/gim

    async function getSitemapURLs() {
      const origin = process.env.NEXT_PUBLIC_SITE_URL

      const index = await fetch("/sitemap.xml")
      const body = await index.text()
      const sitemapURLs = body.match(URL)

      const URLs = await Promise.all(
        sitemapURLs.map(async (sitemapURL) => {
          const sitemap = await fetch(sitemapURL.replace(origin, ""))
          const body = await sitemap.text()
          const URLs = body
            .match(URL)
            ?.map((URL) => URL.replace(origin, ""))
            .filter((URL) => URL !== "")

          return URLs
        }),
      )

      const fuse = new Fuse(URLs.flat(), {
        ignoreLocation: true,
        threshold: 0.4,
      })
      const results = fuse.search(router.asPath)
      setResults(results.slice(0, 10))
    }

    getSitemapURLs()
  }, [router.asPath])

  return (
    <main className="page-content">
      <Meta info={meta} />

      <div className="container mx-auto h-full py-12 md:py-24">
        <div className="mx-auto h-full flex flex-col items-center justify-center text-center">
          <div className="eyebrow-1 text-purple-bright">404</div>
          <h1 className="font-bold text-blue pb-8">Page Not Found</h1>
          <p className=" text-blue text-m2 lg:text-m3 2xl:text-l1 pb-8 max-w-xl ">
            Sorry, the page you{"'"}re trying to access could not be found. It may have been deleted or moved.
          </p>
          <div className="px-10 lg:px-0">
            {results?.length ? (
              <>
                <h2 className="font-bold pb-4 text-blue">Did You Mean...</h2>
                <ul className="pb-12">
                  {results.map((result) => (
                    <li className="pt-6 first:pt-0" key={result.item}>
                      <Link
                        className="underline text-blue visited:text-purple-bright hover:text-green-a11y"
                        href={result.item}
                      >
                        {`/${result.item}`}
                      </Link>
                    </li>
                  ))}
                </ul>
              </>
            ) : null}
            <CallToAction href="/" style="primary">
              RETURN HOME
            </CallToAction>
          </div>
        </div>
      </div>
    </main>
  )
}

export async function getStaticProps({ preview = null }) {
  const globals = await getGlobals()

  return {
    props: {
      preview,
      globals,
    },
  }
}
